import { Flex, Embed } from "theme-ui";
import Script from "next/script";

export const DestiniWidget = ({ section }) => {
  return (
    <>
      <Script
        type="text/javascript"
        id="destini-gtm"
        strategy="afterInteractive"
        src="//destinilocators.com/control/gtm.js"
      />
      <Script
        type="text/javascript"
        id="destini-pscript"
        strategy="afterInteractive"
        src="https://lets.shop/productFirstSnippet.js?id=destini-locator"
      />

      {/*
        <script type="text/javascript" src="//destinilocators.com/control/gtm.js"></script>
        <script type="text/javascript" src=""></script>
      */}

      <Flex
        sx={{
          py: [11, null, null, 14],
          mx: "auto",
        }}
      >
        <div
          id="destini-locator"
          className="destini-locator-class"
          locator-id="4303"
          alpha-code="10CF"
          locator-name="Truff OCL Store Locator"
          client-id="truffhotsauce"
          style={{
            width: "100%",
          }}
        />
      </Flex>
    </>
  );
};

DestiniWidget.Schema = {
  label: "Destini Widget",
  key: "destini-widget",
  fields: [],
};
